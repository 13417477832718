import { Link } from "@remix-run/react";
import { forwardRef } from "react";

import { Button } from "~/components/ui/button.js";

const PricingSection = forwardRef<HTMLElement>((props, ref) => {
  return (
    <section
      className="mx-auto flex max-w-screen-2xl flex-col items-stretch justify-center gap-10 p-5 py-16 md:py-32"
      ref={ref}
    >
      <h2 className="text-center text-4xl font-bold md:text-6xl">Pricing</h2>
      <div className="flex flex-col items-stretch justify-center gap-6 text-center md:flex-row">
        <PricingCard
          title="Free"
          description="Ideal for individuals"
          price="$0"
          features={[
            "Publish unlimited walkthroughs",
            "Unlimited viewers",
            "hosted on annotate.dev",
            "Limited support",
          ]}
        />
        <PricingCard
          title="Pro"
          description="Ideal for teams"
          price="$40/mo"
          features={[
            "Everything in free",
            "Embeddable or hosted on annotate.dev",
            "Includes 3 seats",
            "$10/mo for additional seats",
            "Priority support",
          ]}
        />
      </div>
    </section>
  );
});

PricingSection.displayName = "PricingSection";

interface PricingCardProps {
  title: string;
  description: string;
  price: string;
  features: string[];
}

function PricingCard({
  title,
  description,
  price,
  features,
}: PricingCardProps) {
  return (
    <div className="flex-grow-0 basis-[384px] rounded-md border border-tokyo-border p-12 md:w-[384px]">
      <h3 className="text-3xl font-medium">{title}</h3>
      <p>{description}</p>
      <div className="my-4 text-4xl text-tokyo-text-2">{price}</div>
      <Button className="mb-6 mt-3 w-full" asChild>
        <Link to="/sign-up">Get started</Link>
      </Button>
      <ul className="space-y-4">
        {features.map((feature) => (
          <li key={feature}>{feature}</li>
        ))}
      </ul>
    </div>
  );
}

export default PricingSection;
