import { Link } from "@remix-run/react";
import { forwardRef } from "react";

import { Button } from "~/components/ui/button.js";

const ForEducatorsSection = forwardRef<HTMLElement>((props, ref) => {
  return (
    <section
      className="mx-auto mt-10 flex max-w-screen-2xl flex-col-reverse items-center gap-10 p-5 py-16 text-center md:mt-0  md:flex-row md:py-32 md:text-left"
      ref={ref}
    >
      <div className="flex-1">
        <img
          src="collateral-2.webp"
          className="rounded-md border border-tokyo-border-subtle shadow-lg"
          alt=""
        />
      </div>
      <div className="flex flex-1 flex-col justify-center">
        <div className="flex flex-col justify-center">
          <div className="mb-2 opacity-70">Educators</div>
          <h2 className=" text-4xl font-bold md:text-6xl">
            Create material that your students love
          </h2>
          <p className="mt-5 text-xl md:mt-10 md:text-2xl">
            Annotate code from a single line to entire blocks. Create
            explanations that match your students' learning pace—from in-depth
            analysis of a single line to a quick overview of a code section.
            Help your students understand better, in the way that works best for
            them.
          </p>
          <div className="mt-10">
            <Button size="xl" className="w-full md:w-auto" asChild>
              <Link prefetch="intent" to="/sign-up">
                Get started
              </Link>
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
});

ForEducatorsSection.displayName = "ForEducatorsSection";

export default ForEducatorsSection;
