import { Link } from "@remix-run/react";

import { Button } from "~/components/ui/button.js";

interface HeroSectionProps {
  sampleWalkthroughSectionRef: React.RefObject<HTMLElement>;
}

export default function HeroSection({
  sampleWalkthroughSectionRef,
}: HeroSectionProps) {
  return (
    <section className="mx-auto mt-16 max-w-screen-2xl px-5 py-16 md:py-32">
      <h1 className="text-center text-4xl font-bold md:text-6xl">
        Craft walkthroughs that clarify code
      </h1>
      <p className="mx-auto mt-5 text-center text-lg leading-normal md:mt-10 md:max-w-screen-lg md:text-2xl">
        Annotate makes it easy for devrels, educators, and engineers to explain
        code step-by-step. Write, edit, and publish code walkthroughs. Embed
        directly inside your application.
      </p>
      <div className="mt-10 flex flex-col justify-center gap-4 md:flex-row">
        <Button size="xl" asChild>
          <Link prefetch="intent" to="/sign-up">
            Get started
          </Link>
        </Button>
        <Button
          size="xl"
          variant="outline"
          className="relative inline-flex"
          onClick={() => {
            sampleWalkthroughSectionRef.current?.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }}
        >
          See sample walkthrough
          <img
            className="absolute right-0 top-1/2 -translate-y-3 translate-x-3 -rotate-45 -scale-x-100 md:left-full md:top-1/2 md:h-[120px] md:w-[120px] md:-translate-x-3"
            src="squiggly-line.svg"
            alt=""
          />
        </Button>
      </div>
    </section>
  );
}
