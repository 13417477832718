import { forwardRef } from "react";

import ContactUsForm from "./contact-us-form.js";

const ContactUsSection = forwardRef<HTMLElement>((props, ref) => {
  return (
    <section
      className="mx-auto flex max-w-screen-md items-center justify-center p-5 py-16 md:py-32"
      ref={ref}
    >
      <div>
        <h2 className="text-center text-4xl font-bold md:text-6xl">
          Contact us
        </h2>
        <p className="mt-3 text-center text-xl md:text-2xl">
          Have any thoughts, questions or concerns? We'd love to hear from you.
        </p>
        <ContactUsForm />
      </div>
    </section>
  );
});

ContactUsSection.displayName = "ContactUsSection";

export default ContactUsSection;
