import { Link } from "@remix-run/react";
import { MenuIcon } from "lucide-react";
import { useState } from "react";

import { Button } from "~/components/ui/button.js";
import { Sheet, SheetContent, SheetTrigger } from "~/components/ui/sheet.js";

interface NavbarProps {
  sampleWalkthroughSectionRef: React.RefObject<HTMLElement>;
  howItWorksSectionRef: React.RefObject<HTMLElement>;
  forDevrelsSectionRef: React.RefObject<HTMLElement>;
  forEducatorsSectionRef: React.RefObject<HTMLElement>;
  pricingSectionRef: React.RefObject<HTMLElement>;
  contactUsSectionRef: React.RefObject<HTMLElement>;
}

export default function Navbar(props: NavbarProps) {
  return (
    <>
      <DesktopNavBar {...props} />
      <MobileNavBar {...props} />
    </>
  );
}

function DesktopNavBar(props: NavbarProps) {
  const {
    sampleWalkthroughSectionRef,
    howItWorksSectionRef,
    forDevrelsSectionRef,
    forEducatorsSectionRef,
    pricingSectionRef,
    contactUsSectionRef,
  } = props;

  return (
    <nav className="fixed top-0 z-10 hidden w-full border-b border-b-tokyo-border bg-tokyo-dark-900/80 px-5 backdrop-blur-md md:block">
      <div className="mx-auto flex max-w-screen-2xl justify-between py-3">
        <img src="/worded-logo-light.svg" className="h-10 w-auto" alt="" />
        <div>
          <NavLink sectionRef={sampleWalkthroughSectionRef}>
            Sample Walkthrough
          </NavLink>
          <NavLink sectionRef={howItWorksSectionRef}>How It Works</NavLink>
          <NavLink sectionRef={forDevrelsSectionRef}>For Devrels</NavLink>
          <NavLink sectionRef={forEducatorsSectionRef}>For Educators</NavLink>
          <NavLink sectionRef={pricingSectionRef}>Pricing</NavLink>
          <NavLink sectionRef={contactUsSectionRef}>Contact Us</NavLink>
        </div>
        <div>
          <Button variant="link" asChild>
            <Link prefetch="intent" to="/sign-in">
              Sign in
            </Link>
          </Button>
          <Button asChild>
            <Link prefetch="intent" to="/sign-up">
              Sign up
            </Link>
          </Button>
        </div>
      </div>
    </nav>
  );
}

function MobileNavBar(props: NavbarProps) {
  const {
    howItWorksSectionRef,
    forDevrelsSectionRef,
    forEducatorsSectionRef,
    pricingSectionRef,
    contactUsSectionRef,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);

  return (
    <nav className="fixed top-0 z-10 block w-full border-b border-b-tokyo-border bg-tokyo-dark-900/80 px-5 backdrop-blur-md md:hidden">
      <div className="mx-auto flex max-w-screen-2xl justify-between py-3">
        <img src="/worded-logo-light.svg" className="h-10 w-auto" alt="" />
        <Sheet open={isOpen} onOpenChange={setIsOpen}>
          <SheetTrigger asChild>
            <Button
              className="-mr-3"
              variant="link"
              size="icon"
              aria-label="Menu"
            >
              <MenuIcon />
            </Button>
          </SheetTrigger>
          <SheetContent className="flex flex-col items-start">
            <div className="mb-10">
              <Button variant="link" asChild>
                <Link prefetch="intent" to="/sign-in">
                  Sign in
                </Link>
              </Button>
              <Button asChild>
                <Link prefetch="intent" to="/sign-up">
                  Sign up
                </Link>
              </Button>
            </div>
            <Button className="text-2xl" variant={"link"} asChild>
              <a href="https://annotate.dev/p/hello-world/learn-oauth-2-0-by-building-your-own-oauth-client-U2HaZNtvQojn4F">
                Sample walkthrough
              </a>
            </Button>
            <NavLink sectionRef={howItWorksSectionRef} onClick={handleClose}>
              How It Works
            </NavLink>
            <NavLink sectionRef={forDevrelsSectionRef} onClick={handleClose}>
              For Devrels
            </NavLink>
            <NavLink sectionRef={forEducatorsSectionRef} onClick={handleClose}>
              For Educators
            </NavLink>
            <NavLink sectionRef={pricingSectionRef} onClick={handleClose}>
              Pricing
            </NavLink>
            <NavLink sectionRef={contactUsSectionRef} onClick={handleClose}>
              Contact Us
            </NavLink>
          </SheetContent>
        </Sheet>
      </div>
    </nav>
  );
}

interface NavLinkProps {
  sectionRef: React.RefObject<HTMLElement>;
  children: React.ReactNode;
  onClick?: () => void;
}

function NavLink({ sectionRef, children, onClick }: NavLinkProps) {
  const handleClick = () => {
    sectionRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    onClick?.();
  };

  return (
    <Button
      variant="link"
      className="text-2xl md:text-base"
      onClick={handleClick}
    >
      {children}
    </Button>
  );
}
