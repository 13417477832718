import { type WalkthroughData, WalkthroughView } from "@annotate-dev/react";

interface Props {
  walkthroughData: WalkthroughData;
}

export default function EmbedInstructions({ walkthroughData }: Props) {
  return (
    <section className="mx-auto mt-10 flex max-w-screen-2xl flex-col items-center p-5 py-16 md:mt-0 md:py-32 md:text-left">
      <h2 className="max-w-[30ch] text-balance text-center text-4xl font-bold md:text-6xl">
        Embed inside your React application <br /> in under a minute
      </h2>
      <p className="mt-5 text-balance text-center text-xl md:text-2xl">
        Annotate seamlessly embeds into any React application. SSR and RSC
        compatible.
      </p>

      <div className="mt-10 h-[80dvh] w-full max-w-[1280px] overflow-hidden rounded-md border border-tokyo-border md:h-[800px]">
        <WalkthroughView data={walkthroughData} />
      </div>
    </section>
  );
}
