import { forwardRef } from "react";

const HowItWorksSection = forwardRef<HTMLElement>((props, ref) => {
  return (
    <section
      className="mx-auto flex max-w-screen-2xl flex-col items-center justify-center px-5 py-16 md:py-32"
      ref={ref}
    >
      <h2 className="text-center text-4xl font-bold md:text-6xl">
        How it works
      </h2>
      <p className="mt-3 text-lg">Create a walkthrough in three easy steps.</p>
      <div className="mt-10 flex flex-col justify-center gap-8 text-center md:flex-row">
        <StepCard
          step={1}
          title="Upload"
          description="Upload or copy your code sample that you want to explain into Annotate."
        />
        <StepCard
          step={2}
          title="Annotate"
          description="Highlight code and add markdown notes for clarity. Guide readers through your code with a step-by-step walkthrough."
        />
        <StepCard
          step={3}
          title="Publish"
          description="Make your walkthrough public. Share a unique URL or embed it directly into your application."
        />
      </div>
    </section>
  );
});

HowItWorksSection.displayName = "HowItWorksSection";

interface StepCardProps {
  step: number;
  title: string;
  description: string;
}

function StepCard({ step, title, description }: StepCardProps) {
  return (
    <div className="rounded-md border border-tokyo-border p-5 md:p-12">
      <div className="mx-auto h-8 w-8 rounded-full bg-tokyo-orange-300 text-center leading-8 text-tokyo-orange-900">
        {step}
      </div>
      <h3 className="mt-4 text-3xl font-medium">{title}</h3>
      <p className="mt-5 max-w-[30ch] text-balance">{description}</p>
    </div>
  );
}

export default HowItWorksSection;
