import { getWalkthroughData } from "@annotate-dev/react";
import { json, useLoaderData } from "@remix-run/react";
import { Value } from "@sinclair/typebox/value";
import { useRef } from "react";
import { actionWrapper } from "~/lib/utils.server.js";

import { schema } from "./contact-us-form.js";
import ContactUsSection from "./contact-us-section.js";
import EmbedInstructions from "./embed-instructions.js";
import ForDevRelsSection from "./for-dev-rels-section.js";
import ForEducatorsSection from "./for-educators-section.js";
import HeroSection from "./hero-section.js";
import HowItWorksSection from "./how-it-works-section.js";
import Navbar from "./navbar.js";
import PricingSection from "./pricing-section.js";
import SampleWalkthroughSection from "./sample-walkthrough-section.js";

export const loader = async () => {
  // TODO: Use an ENV variable here
  const sampleWalkthroughId = import.meta.env.PROD
    ? "U2HaZNtvQojn4F"
    : "FdMyGmiGoli3ZB";

  const howToEmbedWalkthroughId = import.meta.env.PROD
    ? "v7EeqWlRxjJHDE"
    : "FdMyGmiGoli3ZB";

  const [sampleWalkthroughData, howToEmbedWalkthroughData] = await Promise.all([
    getWalkthroughData(sampleWalkthroughId),
    getWalkthroughData(howToEmbedWalkthroughId),
  ]);

  return json({ sampleWalkthroughData, howToEmbedWalkthroughData });
};

export const action = actionWrapper(async ({ request, api }) => {
  const formData = await request.formData();
  const data = Object.fromEntries(formData.entries());

  const honeypotField = formData.get("url");

  if (honeypotField) {
    return json({ success: true });
  }

  if (!Value.Check(schema, data)) {
    throw json(
      { type: "error", message: "Invalid form data" },
      { status: 422 },
    );
  }

  await api.createContactRequest(data);

  return json({ success: true });
});

export default function Route() {
  const sampleWalkthroughSectionRef = useRef<HTMLElement | null>(null);
  const howItWorksSectionRef = useRef<HTMLElement | null>(null);
  const forDevrelsSectionRef = useRef<HTMLElement | null>(null);
  const forEducatorsSectionRef = useRef<HTMLElement | null>(null);
  const contactUsSectionRef = useRef<HTMLElement | null>(null);
  const pricingSectionRef = useRef<HTMLElement | null>(null);

  const { howToEmbedWalkthroughData, sampleWalkthroughData } =
    useLoaderData<typeof loader>();

  return (
    <div>
      <Navbar
        sampleWalkthroughSectionRef={sampleWalkthroughSectionRef}
        howItWorksSectionRef={howItWorksSectionRef}
        forDevrelsSectionRef={forDevrelsSectionRef}
        forEducatorsSectionRef={forEducatorsSectionRef}
        pricingSectionRef={pricingSectionRef}
        contactUsSectionRef={contactUsSectionRef}
      />
      <HeroSection sampleWalkthroughSectionRef={sampleWalkthroughSectionRef} />
      <SampleWalkthroughSection
        walkthroughData={sampleWalkthroughData}
        ref={sampleWalkthroughSectionRef}
      />
      <HowItWorksSection ref={howItWorksSectionRef} />
      <EmbedInstructions walkthroughData={howToEmbedWalkthroughData} />
      <ForDevRelsSection ref={forDevrelsSectionRef} />
      <ForEducatorsSection ref={forEducatorsSectionRef} />
      <PricingSection ref={pricingSectionRef} />
      <ContactUsSection ref={contactUsSectionRef} />
    </div>
  );
}
