import { Link } from "@remix-run/react";
import { forwardRef } from "react";

import { Button } from "~/components/ui/button.js";

const ForDevRelsSection = forwardRef<HTMLElement>((props, ref) => {
  return (
    <section
      className="mx-auto mt-10 flex max-w-screen-2xl flex-col items-center gap-10 p-5 py-16 text-center md:mt-0  md:flex-row md:py-32 md:text-left"
      ref={ref}
    >
      <div className="flex flex-1 flex-col justify-center">
        <div className="mb-2 opacity-70">Developer relations</div>
        <h2 className="text-4xl font-bold md:text-6xl">
          Turn passers-by into users
        </h2>
        <p className="mt-5 text-xl md:mt-10 md:text-2xl">
          Get passive readers to find their <i className="font-bold">aha!</i>{" "}
          moment quick. Create walkthroughs that clarify how to build with your
          product's API. Simplify the learning process and boost conversion
          rates.
        </p>
        <div className="mt-10">
          <Button size="xl" className="w-full md:w-auto" asChild>
            <Link prefetch="intent" to="/sign-up">
              Get started
            </Link>
          </Button>
        </div>
      </div>
      <div className="flex-1">
        <img
          src="collateral-1.webp"
          className="rounded-md border border-tokyo-border-subtle shadow-lg"
          alt=""
        />
      </div>
    </section>
  );
});

ForDevRelsSection.displayName = "ForDevRelsSection";

export default ForDevRelsSection;
