import { type WalkthroughData, WalkthroughView } from "@annotate-dev/react";
import { forwardRef } from "react";

interface Props {
  walkthroughData: WalkthroughData;
}

const SampleWalkthroughSection = forwardRef<HTMLElement, Props>(
  ({ walkthroughData }, ref) => {
    return (
      <section
        className="mx-auto flex max-w-screen-2xl flex-col items-center px-5 md:mt-0"
        ref={ref}
      >
        <div className="h-[80dvh] w-full max-w-[1280px] overflow-hidden rounded-md border border-tokyo-border md:h-[800px]">
          <WalkthroughView data={walkthroughData} />
        </div>
      </section>
    );
  },
);

SampleWalkthroughSection.displayName = "SampleWalkthroughSection";

export default SampleWalkthroughSection;
