import { ajvResolver } from "@hookform/resolvers/ajv";
import {
  Form as RemixForm,
  useActionData,
  useNavigation,
  useSubmit,
} from "@remix-run/react";
import { type Static, Type } from "@sinclair/typebox";
import type { JSONSchemaType } from "ajv";
import { CheckCircle2Icon } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import { Button } from "~/components/ui/button.js";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/ui/form.js";
import { Input } from "~/components/ui/input.js";
import { Textarea } from "~/components/ui/textarea.js";

import { type action } from "./route.js";

export const schema = Type.Object({
  emailAddress: Type.String({
    minLength: 1,
    pattern: "^.+@.+..+$",
    errorMessage: {
      minLength: "Email is required",
      pattern: "Email must be properly formatted",
    },
  }),
  subject: Type.String({
    minLength: 1,
    errorMessage: {
      minLength: "Subject is required",
    },
  }),
  message: Type.String({
    minLength: 1,
    errorMessage: {
      minLength: "Message is required",
    },
  }),
});

export default function ContactUsForm() {
  const form = useForm<Static<typeof schema>>({
    resolver: ajvResolver(schema as JSONSchemaType<Static<typeof schema>>),
    defaultValues: {
      emailAddress: "",
      subject: "",
      message: "",
    },
  });

  const submit = useSubmit();
  const navigation = useNavigation();
  const actionData = useActionData<typeof action>();

  useEffect(() => {
    if (!actionData) {
      return;
    }

    form.reset();
  }, [actionData, form]);

  const busy = navigation.state === "submitting";

  return (
    <Form {...form}>
      <RemixForm
        method="POST"
        className="mt-4"
        onSubmit={(e) => {
          const target = e.currentTarget;
          form.handleSubmit(() => {
            submit(target);
          })(e);
        }}
      >
        <fieldset className="space-y-2">
          <FormField
            control={form.control}
            name="emailAddress"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input placeholder="jane@example.com" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="subject"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Subject</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Brief description of your message"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="message"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Message</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="What would you like to tell us?"
                    rows={3}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* honeypot field to detect bot submission */}
          <input className="hidden" name="url" />
        </fieldset>
        <div className="mt-8 flex flex-col items-center gap-5 md:flex-row">
          <Button
            className="w-full md:w-auto"
            size="xl"
            type="submit"
            loading={busy}
            disabled={busy}
          >
            Submit
          </Button>

          {actionData && (
            <p>
              <CheckCircle2Icon className="mr-1 inline-block" />
              We've received your message and will reach out shortly.
            </p>
          )}
        </div>
      </RemixForm>
    </Form>
  );
}
